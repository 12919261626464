import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {Configuration} from './configuration';
import {HttpClient} from '@angular/common/http';


import {BenutzerApiService} from './api/benutzerApi.service';
import {BerechtigungAntragApiService} from './api/berechtigungAntragApi.service';
import {BerechtigungApiService} from './api/berechtigungApi.service';
import {BetriebApiService} from './api/betriebApi.service';
import {BetriebsleiterChangeAntragApiService} from './api/betriebsleiterChangeAntragApi.service';
import {FileApiService} from './api/fileApi.service';
import {FormelApiService} from './api/formelApi.service';
import {FormelDraftApiService} from './api/formelDraftApi.service';
import {FormelHistoryApiService} from './api/formelHistoryApi.service';
import {InformationApiService} from './api/informationApi.service';
import {KomponentApiService} from './api/komponentApi.service';
import {ReportApiService} from './api/reportApi.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    BenutzerApiService,
    BerechtigungAntragApiService,
    BerechtigungApiService,
    BetriebApiService,
    BetriebsleiterChangeAntragApiService,
    FileApiService,
    FormelApiService,
    FormelDraftApiService,
    FormelHistoryApiService,
    InformationApiService,
    KomponentApiService,
    ReportApiService]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
