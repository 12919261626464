import { Component, EventEmitter, Output } from '@angular/core';
import { BetriebApiService, BetriebDTO } from '../../../backend';
import { SearchConfiguration } from '../../efo-table/efo-table.component';

@Component({
  selector: 'app-betrieb-select-table',
  templateUrl: './betrieb-select-table.component.html',
  styleUrl: './betrieb-select-table.component.scss',
})
export class BetriebSelectTableComponent {
  @Output()
  public onBetriebSelected = new EventEmitter<BetriebDTO>();

  totalElements: number = 0;
  totalPages: number = 0;
  numberOfElements: number = 0;

  betriebe?: Array<BetriebDTO> = [];

  constructor(private readonly betriebApiService: BetriebApiService) {}

  findAll(searchConfiguration: SearchConfiguration) {
    this.betriebApiService
      .findAll(
        searchConfiguration.searchTerm,
        searchConfiguration.sortConfiguration.sortBy,
        searchConfiguration.pageConfiguration.pageNumber,
        searchConfiguration.pageConfiguration.pageSize,
        searchConfiguration.sortConfiguration.direction,
      )
      .subscribe((page) => {
        this.betriebe = page.content;
        if (page.totalElements) {
          this.totalElements = page.totalElements;
        } else {
          this.totalElements = 0;
        }
        if (page.totalPages) {
          this.totalPages = page.totalPages;
        }
        if (page.numberOfElements) {
          this.numberOfElements = page.numberOfElements;
        }
      });
  }

  select(betriebDTO: BetriebDTO) {
    this.onBetriebSelected.emit(betriebDTO);
  }
}
