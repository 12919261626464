import { Component } from '@angular/core';
import {
  BetriebDTO,
  ReportApiService,
  ReportFormelQueryDTO,
  ZusammensetzungDraftDTO,
} from '../../../backend';
import { firstValueFrom } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-formel',
  templateUrl: './report-formel.component.html',
  styleUrl: './report-formel.component.scss',
})
export class ReportFormelComponent {
  selectedBetrieb?: BetriebDTO = undefined;

  constructor(
    private readonly reportApiService: ReportApiService,
    private readonly logger: NGXLogger,
    private translateService: TranslateService,
  ) {}

  protected readonly ZusammensetzungDraftDTO = ZusammensetzungDraftDTO;
  protected readonly Object = Object;

  setBetrieb(betriebDTO: BetriebDTO) {
    this.selectedBetrieb = betriebDTO;
  }

  async generateAndDownload() {
    const betriebDTO = this.selectedBetrieb;
    const queryAsString = JSON.stringify({
      betriebId: betriebDTO?.id,
      language: this.translateService.currentLang,
    });
    const future = firstValueFrom(
      this.reportApiService.download(
        queryAsString as any as ReportFormelQueryDTO,
        'response',
      ),
    );
    const httpResponseWithBlob = await future;
    const filename =
      this.tryToExtractFilenameFromResponse(httpResponseWithBlob);
    const blob = httpResponseWithBlob.body;
    if (blob === null) {
      throw 'Http response has no body (should have a Blob-body).';
    }
    saveAs(blob, filename);
  }

  private tryToExtractFilenameFromResponse(
    response: HttpResponse<Blob>,
  ): string | undefined {
    // The server sets a custom header for the filename. Note: Would also be possible to get the filename
    // from 'Content-Disposition' ... but that's a bit hard to parse (especially for UTF-8 filenames), and there
    // don't seem to be good js/ts libraries available.
    const filenameHeaderName = 'X-EFo2-Filename';
    const maybeFilename = response.headers.get(filenameHeaderName);
    if (maybeFilename !== null) {
      return maybeFilename;
    } else {
      this.logger.warn(
        `Response has no ${filenameHeaderName} header. Server should return that header.`,
      );
      return undefined;
    }
  }

  protected readonly undefined = undefined;
}
