<app-bew-box [label]="'betrieb.admin.title' | translate">
  <app-efo-table
    #betriebSelectTable
    [pageSize]="5"
    [totalElements]="totalElements"
    [totalPages]="totalPages"
    [numberOfElements]="numberOfElements"
    (searchContent)="findAll($event)">
    <tr table-header>
      <th>
        {{ 'betrieb.stammdaten.name' | translate }}
        <app-table-column-sort
          [label]="'name'"
          [sortBy]="
            betriebSelectTable?.sortConfiguration?.sortBy || 'createDate'
          "
          (sortByMe)="
            betriebSelectTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th>
        {{ 'betrieb.stammdaten.strasse' | translate }}
        <app-table-column-sort
          [label]="'strasse'"
          [sortBy]="
            betriebSelectTable?.sortConfiguration?.sortBy || 'createDate'
          "
          (sortByMe)="
            betriebSelectTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th>
        {{ 'betrieb.stammdaten.plz' | translate }}
        <app-table-column-sort
          [label]="'plz'"
          [sortBy]="
            betriebSelectTable?.sortConfiguration?.sortBy || 'createDate'
          "
          (sortByMe)="
            betriebSelectTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th>
        {{ 'betrieb.stammdaten.gln' | translate }}
        <app-table-column-sort
          [label]="'gln'"
          [sortBy]="
            betriebSelectTable?.sortConfiguration?.sortBy || 'createDate'
          "
          (sortByMe)="
            betriebSelectTable.changeSorting($event)
          "></app-table-column-sort>
      </th>
      <th></th>
    </tr>

    <tbody table-body>
      <tr *ngIf="betriebe?.length == 0">
        <td colspan="5">
          {{ 'betrieb.admin.none' | translate }}
        </td>
      </tr>
      <ng-container *ngFor="let betrieb of betriebe; index as i">
        <tr>
          <td>{{ betrieb.name }}</td>
          <td>{{ betrieb.strasse }}</td>
          <td>{{ betrieb.plz }}</td>
          <td>{{ betrieb.gln }}</td>

          <td>
            <button
              class="efo-button blue"
              (click)="select(betrieb)"
              [ngbTooltip]="tooltipSelectBetrieb"
              triggers="hover"
              [openDelay]="500"
              [closeDelay]="300">
              <i class="fa fa-hand"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </app-efo-table>
</app-bew-box>

<ng-template #tooltipSelectBetrieb>
  <div [innerHTML]="'tooltip.betrieb_select' | translate"></div>
</ng-template>
