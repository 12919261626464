/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {ReportFormelQueryDTO} from '../model/reportFormelQueryDTO';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class ReportApiService {

  protected basePath = 'http://localhost:8094';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   *
   * @param query
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public download(query: ReportFormelQueryDTO, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public download(query: ReportFormelQueryDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public download(query: ReportFormelQueryDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public download(query: ReportFormelQueryDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (query === null || query === undefined) {
      throw new Error('Required parameter query was null or undefined when calling download.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request('get', `${this.basePath}/api/report/formel`,
      {
        params: queryParameters,
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
