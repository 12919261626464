import { Component, ViewChild } from '@angular/core';
import { AntragCurrent } from '../antrag/antrag-current/antrag-current';
import { BetriebAllOverviewComponent } from '../antrag/betrieb-all-overview/betrieb-all-overview.component';
import { BerechtigungCurrentOverviewComponent } from '../berechtigung-current-overview/berechtigung-current-overview.component';
import { BerechtigungService } from '../../../services/berechtigung.service';
import { BetriebCurrentDetailComponent } from '../../betrieb/betrieb-current-detail/betrieb-current-detail.component';
import { PadDetailComponent } from '../../pad/pad-detail/pad-detail.component';
import { AntragBetriebsleiterChangeCurrentComponent } from '../antrag/antrag-betriebsleiter-change-current/antrag-betriebsleiter-change-current.component';
import { BetriebDTO } from '../../../backend';

@Component({
  selector: 'app-betrieb-overview',
  templateUrl: './berechtigung-overview.component.html',
  styleUrl: './berechtigung-overview.component.scss',
})
export class BerechtigungOverviewComponent {
  @ViewChild(AntragCurrent)
  betriebBerechtigungBeantragtOverviewComponent!: AntragCurrent;
  @ViewChild(BetriebAllOverviewComponent)
  betriebAllOverviewComponent!: BetriebAllOverviewComponent;
  @ViewChild(BerechtigungCurrentOverviewComponent)
  berechtigungCurrentOverviewComponent!: BerechtigungCurrentOverviewComponent;
  @ViewChild(AntragBetriebsleiterChangeCurrentComponent)
  antragBetriebsleiterChangeCurrentComponent!: AntragBetriebsleiterChangeCurrentComponent;
  @ViewChild(BetriebCurrentDetailComponent)
  betriebCurrentDetailComponent!: BetriebCurrentDetailComponent;
  @ViewChild(PadDetailComponent)
  padDetailComponent!: PadDetailComponent;

  constructor(public readonly berechtigungService: BerechtigungService) {}

  reload() {
    this.berechtigungCurrentOverviewComponent.initView();
    this.betriebBerechtigungBeantragtOverviewComponent.searchAll();
    this.betriebAllOverviewComponent.findAllBerechtigungAntraege();
    this.betriebCurrentDetailComponent.searchAll();
    this.antragBetriebsleiterChangeCurrentComponent.searchAll();
    this.padDetailComponent.searchAll();
  }

  selectBerechtigung(betriebDTO: BetriebDTO) {
    this.berechtigungService.setBetrieb(betriebDTO);
  }
}
