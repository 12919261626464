<app-bew-box [label]="'report.formel.title' | translate">
  <app-bew-box [label]="'report.formel.generate.title' | translate">
    <div class="row">
      <span class="col-11">
        <span *ngIf="!selectedBetrieb">
          {{ 'report.formel.generate.info_betrieb_not_selected' | translate }}
        </span>
        <span *ngIf="selectedBetrieb">
          {{ 'report.formel.generate.selected_betrieb' | translate }} :
          <b>{{ selectedBetrieb.name }}</b>
        </span>
      </span>

      <span class="col-1">
        <button
          type="button"
          class="efo-button blue"
          [disabled]="!selectedBetrieb"
          (click)="generateAndDownload()">
          <i class="fa fa-download"></i>
        </button>
      </span>
    </div>
  </app-bew-box>

  <app-betrieb-select-table
    (onBetriebSelected)="setBetrieb($event)"></app-betrieb-select-table>
</app-bew-box>
