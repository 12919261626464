import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BewModule } from '../bew/bew.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { FormelDetailComponent } from './components/formel/formel-detail/formel-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { ApiModule, Configuration } from './backend';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MultiFileUploadComponent } from './components/multi-file-upload/multi-file-upload.component';
import { DefaultFileFilterComponent } from './components/default-file-filter/default-file-filter.component';
import { TableColumnSortComponent } from './components/table-column-sort/table-column-sort.component';
import { FormelOverviewComponent } from './components/formel/real/formel-overview/formel-overview.component';
import {
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { authModuleConfig, authModuleStorage } from './auth-module-config';
import { HomeComponent } from './components/home/home.component';
import { ApiConfiguration } from './api.config';
import { BerechtigungOverviewComponent } from './components/berechtigung/berechtigung-overview/berechtigung-overview.component';
import { BerechtigungCurrentOverviewComponent } from './components/berechtigung/berechtigung-current-overview/berechtigung-current-overview.component';
import { AntragCurrent } from './components/berechtigung/antrag/antrag-current/antrag-current';
import { BetriebAllOverviewComponent } from './components/berechtigung/antrag/betrieb-all-overview/betrieb-all-overview.component';
import { BetriebBerechtigungBeantragenComponent } from './components/betrieb-berechtigung-beantragen/betrieb-berechtigung-beantragen.component';
import { UpdateRolleComponent } from './components/update-rolle/update-rolle.component';
import { EfoTableComponent } from './components/efo-table/efo-table.component';
import { BetriebCurrentDetailComponent } from './components/betrieb/betrieb-current-detail/betrieb-current-detail.component';
import { BerechtigungBeantragenPadComponent } from './components/berechtigung/antrag/berechtigung-beantragen-pad/berechtigung-beantragen-pad.component';
import { PadDetailComponent } from './components/pad/pad-detail/pad-detail.component';
import { ErrorNoBerechtigungForSiteComponent } from './components/error/error-no-berechtigung-for-site/error-no-berechtigung-for-site.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AntragBetriebsleiterChangeCurrentComponent } from './components/berechtigung/antrag/antrag-betriebsleiter-change-current/antrag-betriebsleiter-change-current.component';
import { RechtlichesComponent } from './components/general/rechtliches/rechtliches.component';
import { InfoComponent } from './components/general/info/info.component';
import { ImpressumComponent } from './components/general/impressum/impressum.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FormelHistoryOverviewComponent } from './components/formel/history/formel-history-overview/formel-history-overview.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorService } from './interceptors/http-error.service';
import { ReportComponent } from './components/report/report/report.component';
import { ReportFormelComponent } from './components/report/report-formel/report-formel.component';
import { BetriebSelectTableComponent } from './components/betrieb/betrieb-select-table/betrieb-select-table.component';

@NgModule({
  declarations: [
    AppComponent,
    FormelDetailComponent,
    MultiFileUploadComponent,
    DefaultFileFilterComponent,
    TableColumnSortComponent,
    FormelOverviewComponent,
    FormelHistoryOverviewComponent,
    HomeComponent,
    BerechtigungOverviewComponent,
    BerechtigungCurrentOverviewComponent,
    AntragCurrent,
    AntragBetriebsleiterChangeCurrentComponent,
    BetriebAllOverviewComponent,
    BetriebBerechtigungBeantragenComponent,
    UpdateRolleComponent,
    EfoTableComponent,
    BetriebCurrentDetailComponent,
    BerechtigungBeantragenPadComponent,
    PadDetailComponent,
    ErrorNoBerechtigungForSiteComponent,
    RechtlichesComponent,
    InfoComponent,
    ImpressumComponent,
    ErrorModalComponent,
    ReportComponent,
    ReportFormelComponent,
    BetriebSelectTableComponent,
  ],
  imports: [
    DragDropModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BewModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    TranslateModule,
    ApiModule,
    HttpClientModule,
    NgbModule,
    OAuthModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [
    { provide: OAuthModuleConfig, useFactory: authModuleConfig },
    { provide: Configuration, useClass: ApiConfiguration },
    { provide: OAuthStorage, useFactory: authModuleStorage },
    NgbActiveModal,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
